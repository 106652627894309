<template>
    <div id="app">
        <FormBuilder />
    </div>
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";
import FormBuilder from "./components/FormBuilder.vue";

export default {
    name: "App",
    components: {
        FormBuilder,
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
</style>
